#custom-toolTip-container {
  position: relative;
  width: max-content;
  display: inline-block;
}

/* General style for tooltip */
#custom-toolTip-container .custom-toolTip {
  background: #18223a;
  color: white;
  padding: 10px;
  position: absolute;
  border-radius: 7px;
  left: 50%;
  transform: translateX(calc(-50% + 5px)) translateY(calc(-100% - 10px));
  top: 0px;
  z-index: 9999999999999;
  display: none;
  width: max-content;
  max-width: 200px;
  white-space: normal;
  word-wrap: break-word;
}

/* Tooltip arrow */
#custom-toolTip-container .custom-toolTip .custom-toolTip-index {
  position: absolute;
  bottom: 0;
  width: 7px;
  height: 7px;
  left: 50%;
  background: #18223a;
  z-index: 0;
  transform: translate(-50%, 50%) rotate(-45deg);
}

#custom-toolTip-container:hover .custom-toolTip {
  display: block;
}

/* Styles for position "left" */
#custom-toolTip-container:hover .custom-toolTip.left {
  left: 0;
  transform: translateX(-105%) translateY(-25%);
}

#custom-toolTip-container .custom-toolTip.left .custom-toolTip-index {
  right: -5px;
  left: unset;
  top: 50%;
  transform: rotate(-45deg) translateY(-50%);
}

/* Styles for position "right" */
#custom-toolTip-container:hover .custom-toolTip.right {
  right: 0;
  transform: translateX(30px) translateY(calc(-50% + 10px));
}

#custom-toolTip-container .custom-toolTip.right .custom-toolTip-index {
  left: 0px;
  top: 50%;
  transform: rotate(-45deg) translateY(-50%);
}

/* Styles for position "bottom" */
#custom-toolTip-container:hover .custom-toolTip.bottom {
  top: 100%;
  transform: translateX(-30%) translateY(10px);
}

#custom-toolTip-container .custom-toolTip.bottom .custom-toolTip-index {
  top: -4px;
  left: 50%;
  transform: translate(-50%, 0) rotate(-45deg);
}

/* Styles for "onlyTooltip" */

/* Position "top" with onlyTooltip */
#custom-toolTip-container:hover .custom-toolTip.top.onlyTooltip {
  transform: translateX(calc(-50% + 8px)) translateY(calc(-100% - 3px));
}

/* Position "bottom" with onlyTooltip */
#custom-toolTip-container:hover .custom-toolTip.bottom.onlyTooltip {
  transform: translateX(calc(-50% + 8px));
}

/* Position "left" with onlyTooltip */
#custom-toolTip-container:hover .custom-toolTip.left.onlyTooltip {
  transform: translateX(calc(-100% - 10px)) translateY(10%);
}

/* Position "right" with onlyTooltip */
#custom-toolTip-container:hover .custom-toolTip.right.onlyTooltip {
  transform: translateX(100% + 10px) translateY(10%);
}

#custom-toolTip-container .onlyTooltip {
  padding: 5px !important;
  font-size: 12px !important;
}
