#plan_tab {
  width: 100%;
}
#plan_tab .plans_v2_container {
  display: flex;
  gap: 12px;
  margin-top: 10px;
  position: relative;
  border-radius: 5px;
  width: 100%;
  justify-content: space-evenly;
}

#plan_tab #plan-card {
  min-width: 25%;
  max-width: 30%;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0px 0px 8px 0px #0000001a;
  background-color: #fff;
}
#plan_tab #plan-card:nth-child(2) {
  border-left: none;
}
#plan_tab .plan-card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}
#plan_tab .title {
  font-size: 27px;
  font-weight: bold;
  line-height: 24px;
}
#plan_tab .text-price {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
}

#plan_tab .billed_annually_org {
  color: #db48c4;
  position: absolute;
  bottom: -12px;
  left: -5px;
  font-size: 11px;
  width: 100%;
  white-space: nowrap;
}
#plan_tab .billed_annually_org span {
  color: #888;
  margin-left: 4px;
}
#plan_tab .text-price .price {
  margin-right: 10px;
  font-size: 28px;
  font-weight: bold;
}

#plan_tab .plan-card-content {
  width: 100%;
  margin: 0 auto;
}

#plan_tab .cta-button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#plan_tab .plan-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  /* position: absolute;
  bottom: 0; */
  width: 100%;
  border: 1px solid #e66d6d;
  padding: 11px 30px;
  border-radius: 6px;
  background: linear-gradient(96.13deg, #e66d6d -17.49%, #db48c4 150.79%);
  display: flex;
  color: #fff;
  justify-content: center;
  margin: 0 0 30px 0;
}
#plan_tab .plan-card-content .plan-btn.current {
  background: linear-gradient(96.13deg, #e66d6d -17.49%, #db48c4 150.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  display: flex;
  justify-content: center;
}
#plan_tab .select-credits-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
}
#plan_tab .legacy-cd {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
}

#plan_tab .compare_plan_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#plan_tab .compare_plan_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4d68f8;
  font-size: 18px;
  font-weight: 700;
  margin: 60px 0;
  padding: 8px 16px;
  border-radius: 63px;
  border: 1px solid #4d68f8;
}

#plan_tab .compare_plan_btn img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.custom-plan-container {
  justify-content: center;
  background: transparent !important;
  padding-top: 0 !important;
}
.custom-plan-container .plans_v2_container #plan-card {
  width: max-content !important;
  border-left: none !important;
  position: static !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border-top: 5px solid #db48c4;
}
.custom-plan-container .plans_v2_container #plan-card .title {
  margin: 0 !important;
}

.custom-plan-container .plans_v2_container #plan-card .view-more-details {
  /*  display: none;*/
}
.currency-select .currency-label {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  cursor: pointer;
  color: #555;
}
.currency-select .currency-label:hover {
  color: #6f42c1;
}
.currency-select .currency-label.button {
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 5px 10px;
}
.currency-select .currency-label > span {
  background: #6f42c1;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 10px;
}
.currency-select .currency-label:not(button):hover {
  background: linear-gradient(96.13deg, #e66d6d -17.49%, #db48c4 150.79%);
  color: #fff;
}

/* Christmas coupon CSS */
#plan_tab .christmas-title-ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

#plan_tab .christmas-title-ctn h1 {
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 0.7px;
  background-image: linear-gradient(90deg, #e56c6c 0%, #da47c4 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  max-width: 60%;
}

#plan_tab .christmas-title-ctn p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.7px;
  text-decoration-skip-ink: none;
}

#plan_tab .christmas-legal-container {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

#plan_tab .christmas-legal-container p {
  width: 100%;
  max-width: 80%;
  text-wrap: balance;
  text-align: center;

  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.7px;
}
